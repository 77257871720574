<template>
  <section class="flex flex-col m-10">
    <h1 class="text-3xl md:text-5xl gradient-text">
      Extract automagically objects from images!
    </h1>
    <h2 class="text-xl md:text-3xl">
      Automatic background or foreground removal service. Start using our
      real-time RESTful API from
      <a
        href="https://rapidapi.com/objectcut.api/api/background-removal"
        class=" gradient-text"
        target="_blank"
        rel="noopener"
        >RapidAPI</a
      >
      Marketplace immediately!
    </h2>
  </section>
</template>

<script>
export default {
  name: "Hero",
};
</script>
